import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'

import location from '../img/location-pin.svg'
import phone from '../img/phone-icon.svg'
import email from '../img/email-icon.svg'

import facebook from '../img/social/facebook.svg'
import linkedin from '../img/social/linkedin.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer  has-text-white-ter">
        <div className="content has-text-centered">
          {/* <div style={{width: 'fit-content', textAlign: 'center', background: 'white'}}> */}
          <div style={{background: 'white'}}>
            <Link to="/" title="Advance Auto Movers logo">
              <img src={logo} alt="Advance Auto Movers" style={{ width: '14em', height: '10em' }} />
            </Link>
          </div>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/services">
                        Services
                      </Link>
                    </li>

                    <li>
                      <Link className="navbar-item" to="/blog">
                        Latest Stories
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <address>
                    <ul className="menu-list">
                      <li>
                        <span className="navbar-item">
                          <span className="icon">
                            <img src={location} alt="Visit Us" />
                          </span>
                          <a href='https://maps.google.com/?q=Advance+Auto+Movers' rel="noreferrer noopener" target="_blank">
                            <a href='https://maps.apple.com/maps?q=Advance+Auto+Movers' rel="noreferrer noopener" target="_blank">
                              Advance Auto Movers
                              <br/>
                              2420 Hopewell Plantation Dr, Alpharetta, GA 30004
                            </a>
                          </a>
                        </span>
                      </li>
                      <li>
                        <span className="navbar-item">
                          <span className="icon">
                            <img src={phone} alt="Call Us" />
                          </span>
                            <a
                              href="tel:678-435-9348"
                            >
                              (678) 435-9348
                            </a>
                        </span>
                      </li>
                      <li>
                        <span className="navbar-item">
                          <span className="icon">
                            <img src={email} alt="Email Us" />
                          </span>
                            <a
                              href="mailto:advanceautomovers@gmail.com"
                            >
                              Email Our Company
                            </a>
                        </span>
                      </li>
                    </ul>
                  </address>
                  <div className="social">
                    <a title="Visit Advance Auto Movers on facebook" href="https://facebook.com/advanceautomovers">
                      <img
                        src={facebook}
                        alt="Facebook"
                        style={{ width: '1em', height: '1em' }}
                      />
                    </a>
                    <a title="Visit Advance Auto Movers on linkedin" href="https://linkedin.com/company/advance-auto-movers">
                      <img
                        src={linkedin}
                        alt="Linkedin"
                        style={{ width: '1em', height: '1em' }}
                      />
                    </a>
                    <a title="Visit Advance Auto Movers on twitter" href="https://twitter.com/adv_auto_movers">
                      <img
                        className="fas fa-lg"
                        src={twitter}
                        alt="Twitter"
                        style={{ width: '1em', height: '1em' }}
                      />
                    </a>
                  </div>
                </section>
              </div>

              <div className="column is-4">
              <div className="column">
                <table style={{padding:'3px'}}>
                  <thead>
                    <tr>
                      <th colspan="2" style={{borderBottom: '1px solid #333', fontSize: '21px'}}>Open Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Monday</td>
                      <td>7AM - 9PM</td>
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      <td>7AM - 9PM</td>
                    </tr>
                    <tr>
                      <td>Wednesday</td>
                      <td>7AM - 9PM</td>
                    </tr>
                    <tr>
                      <td>Thursday</td>
                      <td>7AM - 9PM</td>
                    </tr>
                    <tr>
                      <td>Friday</td>
                      <td>7AM - 9PM</td>
                    </tr>
                    <tr>
                      <td>Saturday</td>
                      <td>7AM - 9PM</td>
                    </tr>
                    <tr>
                      <td>Sunday</td>
                      <td>9AM - 5PM</td>
                    </tr>
                  </tbody>
                </table>                  
              </div>
                {/* <a title="facebook" href="https://facebook.com/advanceautomovers">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="linkedin" href="https://linkedin.com/company/advance-auto-movers">
                  <img
                    src={linkedin}
                    alt="Linkedin"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/adv_auto_movers">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a> */}
                
              </div>
            </div>

            <div className="columns is-multiline">
              <div className="column is-6">
              <section >
                  <ul style={{listStyle: 'none', display: 'flex'}}>
                    <li>
                      <Link to="/help" className="navbar-item">
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy" className="navbar-item">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/terms">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/sitemap.xml">
                        Sitemap
                      </Link>
                    </li>
                  </ul>
                </section>

              </div>

            </div>

            <strong>Copyright &copy; 2020 <abbr title="Advance Auto Movers LLC">Advance Auto Movers</abbr></strong>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
